import { DataAccess, ResponseMessage } from "../../dataaccess/data.access";
import { EventProfileReservation, EventsI } from "../../pojo/EventI";
import { S25Util } from "../../util/s25-util";

export interface Calendar {
    calendarId?: number;
    isMigrated?: boolean;
    colorCodeCalendars?: boolean;
    filterOnPublishDates?: boolean;
    weekBegins?: number;
    dayDisplayBegins?: number;
    dayDisplayEnds?: number;
    keywords?: string;
    location?: string;
    region?: string;
    timezoneName?: string;
    calendarAddress?: string;
    customFontScript?: string;
    fontScheme?: string;
    defaultWidget?: WidgetType;
    displayName?: string;
    description?: string;
    webName?: string;

    baseBackgroundColor?: string;
    baseTextColor?: string;
    baseLinkColor?: string;
    baseButtonColor?: string;
    baseButtonTextColor?: string;
    baseBorderColor?: string;
    headerColor?: string;
    groupColor?: string;
    groupBackgroundColor?: string;
    subGroupColor?: string;
    subGroupBackgroundColor?: string;
    featuredColor?: string;
    featuredDescLinkColor?: string;
    featuredLinkColor?: string;
    featuredBackgroundColor?: string;
    baseFont?: string;
    baseEmailFont?: string;
    headerFont?: string;
    groupFont?: string;
    subGroupFont?: string;
    baseTodayBackgroundColor?: string;
    headerTransform?: string;
    groupTransform?: Transform;
    subGroupTransform?: string;
    eventBackgroundColor?: string;
    eventBackgroundColorFade?: string;
    eventTextColor?: string;
    eventLinkColor?: string;

    filters?: CalendarFilter[];
    namedRanges?: CalendarNamedRange[];
    nonPublishedFields?: CalendarNonPublishedField[];
    subcalendars?: CalendarSubcalendar[];
    widgets?: CalendarWidget[];
}

export interface CalendarFilter {
    filterId?: number;
    calendarId?: number;
    filterName?: string;
    filterFields?: CalendarFilterField[];
}

export interface CalendarFilterField {
    filterFieldId?: number;
    filterId?: number;
    fieldName?: string;
    fieldValue?: string;
}

export interface CalendarNamedRange {
    namedRangeId?: number;
    calendarId?: number;
    start?: string; // ISO string format for LocalDateTime
    next?: string; // ISO string format for LocalDateTime
    name?: string;
}

export interface CalendarNonPublishedField {
    nonPubFieldId?: number;
    calendarId?: number;
    name?: string;
}

export interface CalendarSubcalendar {
    parentCalendarId?: number;
    calendarId?: number;
    isSelf?: boolean;
    eventBackgroundColor?: string;
    eventBackgroundColorFade?: string;
    eventTextColor?: string;
    eventLinkColor?: string;
    displayName?: string;

    // frontend only
    selected?: boolean;
}

export enum WidgetType {
    table = "table",
}

export enum Group {
    Year = "Year",
    Quarter = "Quarter",
    Month = "Month",
    Week = "Week",
    Day = "Day",
    Custom = "Custom",
}

export enum DateFormat {
    FULL_MONTH_DAY_YEAR = "MMMM d, yyyy", // January 20, 2025
    ABBR_MONTH_DOT_DAY_YEAR = "MMM. d, yyyy", // Jan. 20, 2025
    ABBR_MONTH_DAY_YEAR = "MMM d, yyyy", // Jan 20, 2025
    SLASH_M_D_Y = "M/d/yyyy", // 1/20/2025
    SLASH_MM_DD_Y = "MM/dd/yyyy", // 01/20/2025
    DASH_M_D_Y = "M-d-yyyy", // 1-20-2025
    DASH_MM_DD_Y = "MM-dd-yyyy", // 01-20-2025
    DOT_M_D_Y = "M.d.yyyy", // 1.20.2025
    DOT_MM_DD_Y = "MM.dd.yyyy", // 01.20.2025
    DAY_FULL_MONTH_YEAR = "d MMMM yyyy", // 20 January 2025
    DAY_ABBR_MONTH_DOT_YEAR = "d MMM. yyyy", // 20 Jan. 2025
    DAY_ABBR_MONTH_YEAR = "d MMM yyyy", // 20 Jan 2025
    SLASH_D_M_Y = "d/M/yyyy", // 20/1/2025
    SLASH_DD_MM_Y = "dd/MM/yyyy", // 20/01/2025
    DASH_D_M_Y = "d-M-yyyy", // 20-1-2025
    DASH_DD_MM_Y = "dd-MM-yyyy", // 20-01-2025
    DOT_D_M_Y = "d.M.yyyy", // 20.1.2025
    DOT_DD_MM_Y = "dd.MM.yyyy", // 20.01.2025
    DASH_DD_ABBR_MONTH_Y = "dd-MMM-yyyy", // 20-Jan-2025
    ISO_YYYY_MM_DD = "yyyy-MM-dd", // 2025-01-20
}

export enum DayOfWeekFormat {
    NONE = "None", // No day of the week displayed
    SHORT = "Short", // Wed
    MEDIUM = "Medium", // Wed.
    LONG = "Long", // Wednesday
}

export enum TimeFormat {
    ampm = "ampm", // 8:30am
    AMPM = "AMPM", // 8:30AM
    ampm_period = "ampm_period", // 8:30a.m.
    AMPM_period = "AMPM_period", // 8:30A.M.
    ap = "ap", // 8:30a
    AP = "AP", // 8:30A
    space_ampm = "space_ampm", // 8:30 am
    space_AMPM = "space_AMPM", // 8:30 AM
    space_ampm_period = "space_ampm_period", // 8:30 a.m.
    space_AMPM_period = "space_AMPM_period", // 8:30 A.M.
    space_ap = "space_ap", // 8:30 a
    space_AP = "space_AP", // 8:30 A
    ampm_period_delimeter = "ampm_period_delimeter", // 8.30am
    Military = "Military", // 8:30 (24-hour)
    Military_period = "Military_period", // 8.30 (24-hour)
    Military_zero = "Military_zero", // 08:30 (24-hour)
    Military_period_zero = "Military_period_zero", // 08.30 (24-hour)
    Military_plain = "Military_plain", // 0830 (24-hour)
}

export enum Transform {
    NONE = "none",
    UPPERCASE = "uppercase",
    LOWERCASE = "lowercase",
    CAPITALIZE = "capitalize",
}

export interface CalendarWidget {
    calendarId?: number;
    widgetType?: WidgetType;
    sortDesc?: boolean;
    showAllDayEventDuration?: boolean;
    showEndDate?: boolean;
    showTime?: boolean;
    displayInlineDescription?: boolean;
    showInlineImages?: boolean;
    hideYearGrouping?: boolean;
    mobileMaxImageWidth?: number;
    descriptionCharacterLimit?: number;
    maxImageWidth?: number;
    maxImageHeight?: number;
    placeholderImage?: number;
    settingHeadingLevelStart?: number;
    settingGroupDateFormat?: DateFormat;
    settingGroupDayOfWeekFormat?: DayOfWeekFormat;
    settingCalendarViewLabel?: string;
    pageBy?: string;
    groupBy?: Group;
    sortBy1?: string;
    sortBy2?: string;
    sortBy3?: string;
    groupSpanningMode?: string;
    defaultPageUnit?: string;
    stGroupFontWeight?: string;
    stColumnHeaderWeight?: string;
    stColumnHeaderTransform?: string;
    stGroupColor?: string;
    stColumnHeaderColor?: string;
    stColumnHeaderBackgroundColor?: string;
    stBorderColor?: string;
    stBorderWidth?: string;
    eventDescriptionFont?: string;
    eventDescriptionColor?: string;
    eventTitleWeight?: string;
    eventTitleStyle?: string;
    eventTitleTransform?: string;
    eventTitleDecoration?: string;
    startDateFontWeight?: string;
    startDateWhiteSpace?: string;
    startTimeWhiteSpace?: string;
    inlineDescriptionFontWeight?: string;
    dateFormat?: DateFormat;
    timeFormat?: TimeFormat;
    widgetFields?: CalendarWidgetField[];
}

export interface CalendarWidgetField {
    widgetFieldId?: number;
    calendarId?: number;
    maxChars?: number;
    options?: string;
    displayName?: string;
    widgetType?: string;
    label?: string;
    contentType?: string;
}

export interface FieldGroup {
    sortKeyDt?: Date;
    sortKey?: string;
    name?: string;
    reservations?: EventProfileReservation[];
}

export class CalendarService {
    public static getCalendarByWebName(webName: string): Promise<Calendar> {
        return DataAccess.get<Calendar>(`/calendars/calendar.json?webName=${encodeURIComponent(webName)}`);
    }

    public static getCalendarById(calendarId: number): Promise<Calendar> {
        return DataAccess.get<Calendar>(`/calendars/calendar/${calendarId}.json`);
    }

    public static migrateCalendar(webName: string): Promise<ResponseMessage> {
        return DataAccess.post<ResponseMessage>(`/calendars/migrate.json?webName=${encodeURIComponent(webName)}`);
    }

    public static populateCalendar(webName: string): Promise<ResponseMessage> {
        return DataAccess.post<ResponseMessage>(`/calendars/populate.json?webName=${encodeURIComponent(webName)}`);
    }

    public static setMigrationStatus(webName: string, status: boolean): Promise<ResponseMessage> {
        return DataAccess.post<ResponseMessage>(
            `/calendars/status.json?webName=${encodeURIComponent(webName)}&status=${status}`,
        );
    }

    public static getMigrationStatus(webName: string): Promise<Calendar> {
        return DataAccess.get<Calendar>(`/calendars/migrationStatus.json?webName=${encodeURIComponent(webName)}`);
    }

    public static getEvents(calendarIds: number[], startDt: Date, endDt: Date): Promise<EventsI> {
        return DataAccess.post<EventsI>(
            `/calendars/calendar/events.json?startDt=${S25Util.date.toS25ISODateTimeStr(startDt)}&endDt=${S25Util.date.toS25ISODateTimeStr(endDt)}`,
            calendarIds,
        );
    }
}
