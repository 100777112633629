import { NgModule } from "@angular/core";
import { CalendaringComponent } from "./calendaring.component";
import { S25CheckboxModule } from "../s25-checkbox/s25.checkbox.module";
import { S25ButtonComponent } from "../../standalone/s25.button.component";
import { CalendarTable } from "./calendaring.table.component";
import { NgComponentOutlet, NgTemplateOutlet } from "@angular/common";
import { DynamicContentComponent } from "../dynamic-content/dynamic.content.component";
import { S25LoadingInlineModule } from "../s25-loading/s25.loading.inline.module";

@NgModule({
    declarations: [CalendaringComponent, CalendarTable],
    imports: [
        S25CheckboxModule,
        S25ButtonComponent,
        NgComponentOutlet,
        NgTemplateOutlet,
        DynamicContentComponent,
        S25LoadingInlineModule,
    ],
    exports: [CalendaringComponent, CalendarTable],
    providers: [CalendaringComponent, CalendarTable],
})
export class CalendaringModule {
    constructor() {}
}
