import {
    Calendar,
    CalendarNamedRange,
    CalendarWidget,
    CalendarWidgetField,
    DateFormat,
    DayOfWeekFormat,
    FieldGroup,
    Group,
    TimeFormat,
    Transform,
} from "./calendaring.service";
import { S25Util } from "../../util/s25-util";
import { EventI, EventProfileReservation, EventsI } from "../../pojo/EventI";
import { S25Const } from "../../util/s25-const";
import { CustomAttribute } from "../s25-custom-attribute/s25.custom.attribute.item.formatter";
import { S25ImageComponent } from "../s25-image/s25.image.component";
import { DynamicValue } from "../dynamic-content/dynamic.content.component";

export interface ColumnValue extends DynamicValue {
    type: "text" | "link" | "component";
    value?: string;
}

export class CalendaringUtil {
    public static dateToGroupTransformed(
        date: Date,
        group: Group = Group.Day,
        weekBegins: number = 0,
        dayOfWeekFormat: DayOfWeekFormat = DayOfWeekFormat.NONE,
        dateFormat: DateFormat = DateFormat.FULL_MONTH_DAY_YEAR,
        namedRanges: CalendarNamedRange[] = [],
        transform: Transform = Transform.NONE,
    ): string {
        let formatted = CalendaringUtil.dateToGroup(date, group, weekBegins, dayOfWeekFormat, dateFormat, namedRanges);
        return CalendaringUtil.transform(formatted, transform);
    }

    public static dateToGroup(
        date: Date,
        group: Group = Group.Day,
        weekBegins: number = 0,
        dayOfWeekFormat: DayOfWeekFormat = DayOfWeekFormat.NONE,
        dateFormat: DateFormat = DateFormat.FULL_MONTH_DAY_YEAR,
        namedRanges: CalendarNamedRange[] = [],
    ): string {
        if (!date) {
            return "";
        }
        let formattedDayOfWeek = CalendaringUtil.formatDayOfWeek(date, weekBegins, dayOfWeekFormat);
        switch (group) {
            case Group.Day:
                let formattedDate = CalendaringUtil.formatDate(date, dateFormat);
                if (formattedDayOfWeek) {
                    return `${formattedDayOfWeek}, ${formattedDate}`;
                }
                return formattedDate;
            case Group.Week:
                let formattedStartDate = CalendaringUtil.formatDate(
                    S25Util.date.firstDayOfWeek(date, weekBegins),
                    dateFormat,
                );
                if (formattedDayOfWeek) {
                    return `Week of ${formattedDayOfWeek}, ${formattedStartDate}`;
                }
                return `Week of ${formattedStartDate}`;
            case Group.Month:
                return date.toLocaleDateString("en-US", { month: "long", year: "numeric" }); // "January 2025"
            case Group.Quarter:
                let quarter = Math.floor(date.getMonth() / 3) + 1;
                let namedQuarter = ["First", "Second", "Third", "Fourth"][quarter - 1];
                return `${namedQuarter} Quarter ${date.getFullYear()}`; // "First Quarter 2025"
            case Group.Year:
                return date.getFullYear().toString();
            case Group.Custom:
                namedRanges = namedRanges || [];
                for (let namedRange of namedRanges) {
                    let startDt: Date = new Date(namedRange.start);
                    let nextDt: Date = new Date(namedRange.next);
                    if (date >= startDt && date <= nextDt) {
                        return namedRange.name;
                    }
                }
                return "Other Events";
            default:
                return "Other Events";
        }
    }

    public static formatDayOfWeek(date: Date, weekBegins: number, format: DayOfWeekFormat): string {
        date = S25Util.date.firstDayOfWeek(date, weekBegins);
        switch (format) {
            case DayOfWeekFormat.NONE:
                return ""; // No day displayed
            case DayOfWeekFormat.SHORT:
                return date.toLocaleDateString("en-US", { weekday: "short" }); // "Wed"
            case DayOfWeekFormat.MEDIUM:
                return date.toLocaleDateString("en-US", { weekday: "short" }) + "."; // "Wed."
            case DayOfWeekFormat.LONG:
                return date.toLocaleDateString("en-US", { weekday: "long" }); // "Wednesday"
            default:
                return date.toLocaleDateString("en-US", { weekday: "long" }); // Default to "Wednesday"
        }
    }

    public static formatDate(date: Date, format: DateFormat): string {
        const options: Intl.DateTimeFormatOptions = {};
        switch (format) {
            case DateFormat.FULL_MONTH_DAY_YEAR:
                options.month = "long";
                options.day = "numeric";
                options.year = "numeric";
                break;
            case DateFormat.ABBR_MONTH_DOT_DAY_YEAR:
                options.month = "short";
                options.day = "numeric";
                options.year = "numeric";
                break;
            case DateFormat.ABBR_MONTH_DAY_YEAR:
                options.month = "short";
                options.day = "numeric";
                options.year = "numeric";
                break;
            case DateFormat.SLASH_M_D_Y:
            case DateFormat.SLASH_MM_DD_Y:
                return date.toLocaleDateString("en-US"); // 1/20/2025 or 01/20/2025
            case DateFormat.DASH_M_D_Y:
            case DateFormat.DASH_MM_DD_Y:
                return date.toLocaleDateString("en-US").replace(/\//g, "-");
            case DateFormat.DOT_M_D_Y:
            case DateFormat.DOT_MM_DD_Y:
                return date.toLocaleDateString("en-US").replace(/\//g, ".");
            case DateFormat.DAY_FULL_MONTH_YEAR:
                options.day = "numeric";
                options.month = "long";
                options.year = "numeric";
                break;
            case DateFormat.DAY_ABBR_MONTH_DOT_YEAR:
            case DateFormat.DAY_ABBR_MONTH_YEAR:
                options.day = "numeric";
                options.month = "short";
                options.year = "numeric";
                break;
            case DateFormat.SLASH_D_M_Y:
            case DateFormat.SLASH_DD_MM_Y:
                return date.toLocaleDateString("en-GB"); // 20/1/2025 or 20/01/2025
            case DateFormat.DASH_D_M_Y:
            case DateFormat.DASH_DD_MM_Y:
                return date.toLocaleDateString("en-GB").replace(/\//g, "-");
            case DateFormat.DOT_D_M_Y:
            case DateFormat.DOT_DD_MM_Y:
                return date.toLocaleDateString("en-GB").replace(/\//g, ".");
            case DateFormat.DASH_DD_ABBR_MONTH_Y:
                options.day = "numeric";
                options.month = "short";
                options.year = "numeric";
                break;
            case DateFormat.ISO_YYYY_MM_DD:
                return date.toISOString().split("T")[0]; // YYYY-MM-DD
        }
        return new Intl.DateTimeFormat("en-US", options).format(date);
    }

    public static formatTime(date: Date, format: TimeFormat): string {
        if (!date) return "";

        const hours = date.getHours();
        const minutes = date.getMinutes();
        const paddedMinutes = minutes.toString().padStart(2, "0");

        switch (format) {
            case TimeFormat.ampm:
                return `${CalendaringUtil.format12Hour(hours)}:${paddedMinutes}${CalendaringUtil.getAmPm(hours, false, false)}`;
            case TimeFormat.AMPM:
                return `${CalendaringUtil.format12Hour(hours)}:${paddedMinutes}${CalendaringUtil.getAmPm(hours, true, false)}`;
            case TimeFormat.ampm_period:
                return `${CalendaringUtil.format12Hour(hours)}:${paddedMinutes}${CalendaringUtil.getAmPm(hours, false, true)}`;
            case TimeFormat.AMPM_period:
                return `${CalendaringUtil.format12Hour(hours)}:${paddedMinutes}${CalendaringUtil.getAmPm(hours, true, true)}`;
            case TimeFormat.ap:
                return `${CalendaringUtil.format12Hour(hours)}:${paddedMinutes}${CalendaringUtil.getAmPm(hours, false, false).charAt(0)}`;
            case TimeFormat.AP:
                return `${CalendaringUtil.format12Hour(hours)}:${paddedMinutes}${CalendaringUtil.getAmPm(hours, true, false).charAt(0)}`;
            case TimeFormat.space_ampm:
                return `${CalendaringUtil.format12Hour(hours)}:${paddedMinutes} ${CalendaringUtil.getAmPm(hours, false, false)}`;
            case TimeFormat.space_AMPM:
                return `${CalendaringUtil.format12Hour(hours)}:${paddedMinutes} ${CalendaringUtil.getAmPm(hours, true, false)}`;
            case TimeFormat.space_ampm_period:
                return `${CalendaringUtil.format12Hour(hours)}:${paddedMinutes} ${CalendaringUtil.getAmPm(hours, false, true)}`;
            case TimeFormat.space_AMPM_period:
                return `${CalendaringUtil.format12Hour(hours)}:${paddedMinutes} ${CalendaringUtil.getAmPm(hours, true, true)}`;
            case TimeFormat.space_ap:
                return `${CalendaringUtil.format12Hour(hours)}:${paddedMinutes} ${CalendaringUtil.getAmPm(hours, false, false).charAt(0)}`;
            case TimeFormat.space_AP:
                return `${CalendaringUtil.format12Hour(hours)}:${paddedMinutes} ${CalendaringUtil.getAmPm(hours, true, false).charAt(0)}`;
            case TimeFormat.ampm_period_delimeter:
                return `${CalendaringUtil.format12Hour(hours)}.${paddedMinutes}${CalendaringUtil.getAmPm(hours, false, false)}`;
            case TimeFormat.Military:
                return `${hours}:${paddedMinutes}`;
            case TimeFormat.Military_period:
                return `${hours}.${paddedMinutes}`;
            case TimeFormat.Military_zero:
                return `${hours.toString().padStart(2, "0")}:${paddedMinutes}`;
            case TimeFormat.Military_period_zero:
                return `${hours.toString().padStart(2, "0")}.${paddedMinutes}`;
            case TimeFormat.Military_plain:
                return `${hours.toString().padStart(2, "0")}${paddedMinutes}`;
            default:
                return `${CalendaringUtil.format12Hour(hours)}:${paddedMinutes}${CalendaringUtil.getAmPm(hours, false, false)}`;
        }
    }

    public static transform(value: string, transform: Transform): string {
        switch (transform) {
            case Transform.UPPERCASE:
                return value.toUpperCase();
            case Transform.LOWERCASE:
                return value.toLowerCase();
            case Transform.CAPITALIZE:
                // split the string into words and capitalize the first letter of each word
                return value
                    .split(" ")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ");
            case Transform.NONE:
                return value;
            default:
                return value;
        }
    }

    public static format12Hour(hours: number): number {
        return hours % 12 === 0 ? 12 : hours % 12;
    }

    public static getAmPm(hours: number, uppercase: boolean, period: boolean): string {
        let ampm = hours >= 12 ? "pm" : "am";
        if (uppercase) ampm = ampm.toUpperCase();
        if (period) ampm = `${ampm.charAt(0)}.${ampm.charAt(1)}.`;
        return ampm;
    }

    public static getColumnValue(
        calendar: Calendar,
        widget: CalendarWidget,
        column: CalendarWidgetField,
        event: EventI,
        reservation: EventProfileReservation,
    ): ColumnValue {
        switch (column.contentType) {
            case "CalendarName":
                return { type: "text", value: calendar.displayName ?? "" };

            case "Note":
                return { type: "text", value: event.event_text?.find((text) => text.text_type_id === 2)?.text ?? "" };

            case "Duration":
                return { type: "text", value: CalendaringUtil.getReservationDuration(reservation) };

            case "EndDate":
                return {
                    type: "text",
                    value:
                        CalendaringUtil.formatDate(new Date(reservation.reservation_end_dt), widget.dateFormat) ?? "",
                };

            case "EndDayOfWeek":
                return {
                    type: "text",
                    value:
                        CalendaringUtil.formatDayOfWeek(
                            new Date(reservation.reservation_end_dt),
                            calendar.weekBegins,
                            widget.settingGroupDayOfWeekFormat,
                        ) ?? "",
                };

            case "EndTime":
                return {
                    type: "text",
                    value:
                        CalendaringUtil.formatTime(new Date(reservation.reservation_end_dt), widget.timeFormat) ?? "",
                };

            // todo: calendaring event details page (and link to it)
            case "DetailLink":
                return { type: "link", value: `/event/${event.event_id}` };

            case "EventTypeName":
                return { type: "text", value: event.event_type_name ?? "" };

            // todo: difference between these?
            // todo: also: publisher looks to make the string a link to google maps
            //     : when the location has lat/long
            case "Location":
            case "LocationOrVenue":
                return {
                    type: "text",
                    value:
                        event.profile
                            ?.flatMap((p) =>
                                p.reservation?.flatMap((r) =>
                                    r.space_reservation?.flatMap((sp) => sp.space?.space_name),
                                ),
                            )
                            .filter((space) => !!space)
                            .sort((a, b) => S25Util.toStr(a).localeCompare(S25Util.toStr(b)))
                            .join(", ") ?? "",
                };

            case "StartDate":
                return {
                    type: "text",
                    value:
                        CalendaringUtil.formatDate(new Date(reservation.reservation_start_dt), widget.dateFormat) ?? "",
                };

            case "StartDayOfWeek":
                return {
                    type: "text",
                    value:
                        CalendaringUtil.formatDayOfWeek(
                            new Date(reservation.reservation_start_dt),
                            calendar.weekBegins,
                            widget.settingGroupDayOfWeekFormat,
                        ) ?? "",
                };

            case "StartTime":
                return {
                    type: "text",
                    value:
                        CalendaringUtil.formatTime(new Date(reservation.reservation_start_dt), widget.timeFormat) ?? "",
                };

            case "InfoUrl":
                // todo: double check this, it's likely something different
                return { type: "text", value: event.registration_url ?? "" };

            // todo: either use this or the html descr text; publisher seems to use event name but
            //     : check if there's a setting for title vs name?
            case "Description":
                return { type: "text", value: event.event_name };

            case "StartEndRangeSmart": {
                const startDt = new Date(reservation.reservation_start_dt);
                const endDt = new Date(reservation.reservation_end_dt);

                let formattedStart = CalendaringUtil.formatDate(startDt, widget.dateFormat);
                let formattedEnd = CalendaringUtil.formatDate(endDt, widget.dateFormat);

                if (widget.hideYearGrouping && S25Util.date.equalYear(startDt, endDt)) {
                    const startYearToRemove = startDt.getFullYear().toString();
                    const endYearToRemove = endDt.getFullYear().toString();
                    formattedStart = formattedStart.replace(new RegExp(`\\b${startYearToRemove}\\b`), "").trim();
                    formattedEnd = formattedEnd.replace(new RegExp(`\\b${endYearToRemove}\\b`), "").trim();
                }

                let startOutput = `${formattedStart} ${CalendaringUtil.formatTime(startDt, widget.timeFormat)}`;
                let endOutput = S25Util.date.equalDate(startDt, endDt)
                    ? CalendaringUtil.formatTime(endDt, widget.timeFormat)
                    : `${formattedEnd} ${CalendaringUtil.formatTime(endDt, widget.timeFormat)}`;

                return { type: "text", value: `${startOutput} - ${endOutput}` };
            }

            default:
                let customAttribute = event.custom_attribute?.find(
                    (attr) => attr.attribute_name === column.contentType,
                );
                let value = customAttribute?.attribute_value ?? "";

                switch (customAttribute?.attribute_type + "") {
                    case CustomAttribute.type.Date:
                        return { type: "text", value: CalendaringUtil.formatDate(new Date(value), widget.dateFormat) };

                    case CustomAttribute.type.Time:
                        return { type: "text", value: CalendaringUtil.formatTime(new Date(value), widget.timeFormat) };

                    case CustomAttribute.type.Datetime:
                        return {
                            type: "text",
                            value:
                                CalendaringUtil.formatDate(new Date(value), widget.dateFormat) +
                                " " +
                                CalendaringUtil.formatTime(new Date(value), widget.timeFormat),
                        };

                    case CustomAttribute.type.Duration:
                        return {
                            type: "text",
                            value:
                                CalendaringUtil.formatDate(new Date(value), widget.dateFormat) +
                                " " +
                                CalendaringUtil.formatTime(new Date(value), widget.timeFormat),
                        };

                    case CustomAttribute.type.Float:
                        return { type: "text", value: S25Util.toFloat(value).toString() };

                    case CustomAttribute.type.Integer:
                        return { type: "text", value: S25Util.toInt(value).toString() };

                    case CustomAttribute.type.Boolean:
                        return { type: "text", value: S25Util.toBool(value).toString() };

                    case CustomAttribute.type.String:
                    case CustomAttribute.type.LargeText:
                        return { type: "text", value: S25Util.toStr(value) };

                    case CustomAttribute.type.FileReference:
                        return { type: "link", value: S25Util.toStr(value) };

                    case CustomAttribute.type.Image:
                        return {
                            type: "component",
                            component: S25ImageComponent,
                            inputs: {
                                model: {
                                    imageId: S25Util.toInt(value),
                                },
                            },
                        };

                    // todo: implement these
                    case CustomAttribute.type.Organization + "":
                    case CustomAttribute.type.Contact + "":
                    case CustomAttribute.type.Location + "":
                    case CustomAttribute.type.Resource + "":
                        return { type: "text", value: "todo" };
                }

                return { type: "text", value: customAttribute?.attribute_value ?? "" };
        }
    }

    public static getReservationDuration(reservation: EventProfileReservation): string {
        if (!reservation.reservation_start_dt || !reservation.reservation_end_dt) return "";

        let start = new Date(reservation.reservation_start_dt);
        let end = new Date(reservation.reservation_end_dt);
        let diffMs = end.getTime() - start.getTime();

        let days = Math.floor(diffMs / S25Const.ms.day);
        let hours = Math.floor((diffMs % S25Const.ms.day) / S25Const.ms.hour);
        let minutes = Math.floor((diffMs % S25Const.ms.hour) / S25Const.ms.min);

        let parts = [];
        if (days > 0) parts.push(`${days}d`);
        if (hours > 0) parts.push(`${hours}h`);
        if (minutes > 0) parts.push(`${minutes}m`);

        return parts.join(" ") || "0m";
    }

    public static groupReservations(events: EventsI, calendar: Calendar, widget: CalendarWidget): FieldGroup[] {
        const reservationGroups: { [key: string]: FieldGroup } = {};

        for (let event of events?.event ?? []) {
            for (let profile of event.profile ?? []) {
                profile.event = event;
                for (let reservation of profile.reservation ?? []) {
                    reservation.event = event;
                    reservation.profile = profile;

                    let groupKey = CalendaringUtil.dateToGroupTransformed(
                        new Date(reservation.reservation_start_dt),
                        widget.groupBy,
                        calendar.weekBegins,
                        widget.settingGroupDayOfWeekFormat,
                        widget.settingGroupDateFormat,
                        calendar.namedRanges,
                        calendar.groupTransform,
                    );

                    let reservationStartDate = new Date(reservation.reservation_start_dt);

                    if (!reservationGroups[groupKey]) {
                        reservationGroups[groupKey] = {
                            name: groupKey,
                            sortKeyDt: reservationStartDate,
                            reservations: [],
                        };
                    }
                    reservationGroups[groupKey].reservations.push(reservation);

                    // ensure the group’s sort key is always the earliest reservation start date
                    if (reservationStartDate < reservationGroups[groupKey].sortKeyDt) {
                        reservationGroups[groupKey].sortKeyDt = reservationStartDate;
                    }
                }
            }
        }

        // sort groups by the min reservation start date within the group
        let groups = Object.values(reservationGroups).sort((a, b) => a.sortKeyDt.getTime() - b.sortKeyDt.getTime());

        // Sort reservations within each group by reservation start date
        for (let group of groups) {
            group.reservations.sort(
                (a, b) => new Date(a.reservation_start_dt).getTime() - new Date(b.reservation_start_dt).getTime(),
            );
        }

        return groups;
    }
}
