import { TypeManagerDecorator } from "../../main/type.map.service";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { Calendar, CalendarService, WidgetType } from "./calendaring.service";

@TypeManagerDecorator("s25-ng-calendaring")
@Component({
    selector: "s25-ng-calendaring",
    template: `
        @if (init) {
            @switch (widgetType) {
                @case (WidgetType.table) {
                    <s25-ng-calendar-table
                        [calendar]="calendar"
                        [startDt]="startDt"
                        [endDt]="endDt"
                    ></s25-ng-calendar-table>
                }
                @default {
                    <div>Unknown calendar type: [{{ widgetType }}]</div>
                }
            }
        }
    `,
    styleUrls: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendaringComponent implements OnInit {
    constructor(private cd: ChangeDetectorRef) {}

    // one of:
    @Input() calendarId?: number; // fetch calendar by the PK: calendarId
    @Input() webName?: string; // fetch calendar by a unique key: webName
    @Input() widgetType?: WidgetType;
    @Input() startDt?: Date;
    @Input() endDt?: Date;

    @Input() calendar?: Calendar; // useful for easy testing

    init = false;

    async ngOnInit() {
        let urlSearchParams = new URLSearchParams(window.location.search);

        if (!this.calendar && !this.calendarId && !this.webName) {
            this.calendarId = parseInt(urlSearchParams.get("calendarId")) || undefined;
            this.webName = urlSearchParams.get("webName");
        }

        if (!this.startDt && urlSearchParams.get("startDt")) {
            this.startDt = new Date(urlSearchParams.get("startDt"));
        }

        if (!this.endDt && urlSearchParams.get("endDt")) {
            this.endDt = new Date(urlSearchParams.get("endDt"));
        }

        if (!this.widgetType && urlSearchParams.get("widgetType")) {
            this.widgetType = urlSearchParams.get("widgetType") as WidgetType;
        }

        let calendarPromise: Promise<Calendar> = Promise.resolve(this.calendar);

        if (!this.calendar && this.calendarId) {
            // get calendar by id
            calendarPromise = CalendarService.getCalendarById(this.calendarId);
        } else if (!this.calendar && this.webName) {
            // get calendar by webName
            calendarPromise = CalendarService.getCalendarByWebName(this.webName);
        }

        this.calendar = await calendarPromise;
        if (this.calendar) {
            if (!this.widgetType) {
                this.widgetType = this.calendar.defaultWidget;
            }
            this.init = true;
            this.cd.detectChanges();
        }
    }

    protected readonly WidgetType = WidgetType;
}
