import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import {
    Calendar,
    CalendarService,
    CalendarWidget,
    CalendarWidgetField,
    FieldGroup,
    WidgetType,
} from "./calendaring.service";
import { EventI, EventsI, EventProfileReservation } from "../../pojo/EventI";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { CalendaringUtil } from "./calendaring.util";

@TypeManagerDecorator("s25-ng-calendar-table")
@Component({
    selector: "s25-ng-calendar-table",
    template: `
        @if (init) {
            <h1>{{ calendar.displayName }}</h1>
            @if (loading) {
                <s25-ng-loading-inline-static></s25-ng-loading-inline-static>
            }
            @if (calendar.subcalendars?.length) {
                <div class="c-padding-top--single c-padding-bottom--single">
                    @for (subcalendar of calendar.subcalendars; track subcalendar.calendarId) {
                        <s25-ng-checkbox
                            class="c-margin-left--half"
                            [parentCd]="cd"
                            [(modelValue)]="subcalendar.selected"
                            (modelValueChange)="subCalendarChange()"
                        >
                            {{ subcalendar.displayName }}
                        </s25-ng-checkbox>
                    }
                    <div>
                        <s25-ng-button [type]="'flat'" [onClick]="selectAll">All</s25-ng-button>
                        <s25-ng-button [type]="'none'" [onClick]="selectNone">None</s25-ng-button>
                    </div>
                </div>
            }

            <table class="event-table">
                <thead>
                    <tr>
                        @for (column of columns; track column.widgetFieldId) {
                            <th>{{ column.label }}</th>
                        }
                    </tr>
                </thead>
                <tbody>
                    @for (group of groups; track group.sortKey) {
                        <tr class="group-row">
                            <td [attr.colspan]="columns.length">{{ group.name }}</td>
                        </tr>
                        @for (reservation of group.reservations; track reservation.reservation_id) {
                            <tr>
                                @for (column of columns; track column.widgetFieldId) {
                                    @let columnValue =
                                        getColumnValue(calendar, widget, column, reservation.event, reservation);
                                    <td>
                                        @if (columnValue.type === "text") {
                                            {{ columnValue.value }}
                                        } @else if (columnValue.type === "link") {
                                            <a [href]="columnValue.value">{{ columnValue.value }}</a>
                                        } @else {
                                            <s25-ng-dynamic-content
                                                [dynamicValue]="columnValue"
                                            ></s25-ng-dynamic-content>
                                        }
                                    </td>
                                }
                            </tr>
                        }
                    }
                </tbody>
            </table>
        }
    `,
    styles: [
        `
            .event-table {
                width: 100%;
                border-collapse: collapse;
            }
            .event-table th,
            .event-table td {
                border: 1px solid #ddd;
                padding: 8px;
            }
            .group-row {
                background-color: #f2f2f2;
                font-weight: bold;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.Emulated,
})
export class CalendarTable implements OnInit {
    constructor(public cd: ChangeDetectorRef) {}

    @Input() calendar?: Calendar;
    @Input() startDt?: Date;
    @Input() endDt?: Date;

    @Input() events?: EventsI; // useful input for easy testing

    init = false;
    loading = false;
    widget: CalendarWidget;
    columns: CalendarWidgetField[] = [];
    groups: FieldGroup[] = [];

    selectAll = async () => {
        this.calendar.subcalendars.forEach((sc) => (sc.selected = true));
        await this.refresh();
    };

    selectNone = async () => {
        this.calendar.subcalendars.forEach((sc) => (sc.selected = false));
        await this.refresh();
    };

    refresh = async () => {
        this.loading = true;
        this.cd.detectChanges();
        this.events = await this.getEvents();
        this.groups = CalendaringUtil.groupReservations(this.events, this.calendar, this.widget);
        this.loading = false;
        this.cd.detectChanges();
    };

    subCalendarChange = S25Util.debounceTs(() => {
        return this.refresh();
    }, 1000);

    getEvents = async () => {
        let calendarIds = new Set(this.calendar.subcalendars.filter((sc) => sc.selected).map((sc) => sc.calendarId));
        return CalendarService.getEvents(Array.from(calendarIds), this.startDt, this.endDt);
    };

    getColumnValue(
        calendar: Calendar,
        widget: CalendarWidget,
        column: CalendarWidgetField,
        event: EventI,
        reservation: EventProfileReservation,
    ) {
        return CalendaringUtil.getColumnValue(calendar, widget, column, event, reservation);
    }

    async ngOnInit() {
        if (this.calendar) {
            this.startDt = this.startDt || S25Util.date.currentDate();
            this.endDt = this.endDt || S25Util.date.addDays(this.startDt, 7);
            this.calendar.subcalendars ??= [];
            this.calendar.subcalendars.sort((a, b) => {
                let aStr = a.displayName?.toLowerCase() ?? "";
                let bStr = b.displayName?.toLowerCase() ?? "";
                return aStr < bStr ? -1 : aStr > bStr ? 1 : 0;
            });
            this.calendar.subcalendars.forEach((sc) => (sc.selected = true));
            this.widget = this.calendar.widgets.find((widget) => widget.widgetType === WidgetType.table);
            if (!this.widget) {
                throw new Error(`Widget [${WidgetType.table}] not found.`);
            }
            this.columns = [...(this.widget.widgetFields || [])].sort(
                (a, b) => (a.widgetFieldId ?? 0) - (b.widgetFieldId ?? 0),
            );

            this.events = this.events || (await this.getEvents());
            this.groups = CalendaringUtil.groupReservations(this.events, this.calendar, this.widget);

            this.init = true;
            this.cd.detectChanges();
        }
    }
}
